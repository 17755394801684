<template>
	<div class="main-error-body bg-primary-transparent">
		<div class="page">
			<div class="main-error-wrapper  page page-h ">
				<img src="/assets/img/error/400.png" class="error-page" alt="error">
				<h2>Неверный запрос</h2>
				<h6> Этот ответ означает, что сервер не понимает запрос из-за неверного синтаксиса.</h6>
                <a class="btn btn-outline-danger" href="/">Вернуться на главную страницу</a>
			</div>			
		</div>
	</div>
</template>

<script>
export default {
}
</script>